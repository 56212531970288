import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'Joe, a genetically predisposed geek, started his love for binary code when the Atari 2600 was all the rage and kids went to arcades to socialize. He learned his craft through experimenting with Atari BASIC on his trusted 800XL, programming his first video game at 14 years old.',
  paragraphTwo:
    'From there, he learned the now ancient language PASCAL in his last semester of high school before going to the University of Texas at Arlington to study Physics and Aerospace Engineering.',
  paragraphThree:
    "Joe's creativity and vision bundled with his intellect and technical prowess are allowing him to become one of the top developers out there.",
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'vci.png',
    title: 'Volkswagen Credit',
    info:
      '(Project via EmergenceTek Group) Created an online financing application for Volkswagen Credit US and Canada which also doubled for Audi Finance with custom templating which would switch depending on the hosting URL.',
    info2:
      'Technologies for the frontend were React, Redux, Formik and Material UI.  It was hosted on AWS via Concourse / Bifrost to manage continuous development and integration.',
    url:
      'https://finance.vwcredit.com/?adobe_mc=MCMID%3D63278881645089184923913915630151896264%7CMCAID%3D2D899AF405079C81-60000109800087A7%7CMCORGID%3DFB530CEB56FAC9DA7F000101%2540AdobeOrg%7CTS%3D1594779120',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'bbby.png',
    title: 'Bed Bath & Beyond',
    info:
      'Lead developer on both iOS and Android applications.  Implemented first of its kind Augmented Reality and integrated barcode scanner for wedding registry items and coupons using computer vision technology via a custom algorithm made by me.',
    info2:
      "Built on Appcelerator's platform for iOS and Android.  Integrated with BBBY's Oracle ATG web commerce platform.",
    url: 'https://apps.apple.com/us/app/bed-bath-beyond/id495955880',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'vw.png',
    title: 'Volkswagen',
    info:
      '(Project via EmergenceTek Group) Built a custom portal for the California Consumer Protection Act for Volkswagen North America.',
    info2:
      'Technologies for the frontend were React, Redux, Formik, Zoid, Recaptcha, StrikeIron and Material UI.  It was hosted on AWS via Concourse / Bifrost to manage continuous development and integration.',
    url: 'https://privacyportal.volkswagengroupofamerica.com/donotsell',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'jbhunt.png',
    title: 'JB Hunt Transportation',
    info: "Helped with JB Hunt's initiatives on their Carrier 360 platform for iOS and Android.",
    info2:
      "Utilized Appcelerator's framework in Javascript. Azure was the backend, Agile SCRUM was the implementation methodology.",
    url: 'https://apps.apple.com/us/app/carrier-360-by-j-b-hunt/id1097181984',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'mandt.png',
    title: 'M&T Bank',
    info: 'Worked on new mobile banking application which was released in October 2016.',
    info2:
      'Built using Xcode with Swift and Objective-C using Google Maps for the branches / ATMs locator.',
    url: 'https://apps.apple.com/us/app/m-t-mobile-banking/id397761931',
  },
  {
    id: nanoid(),
    img: 'lbwl.png',
    title: 'Lansing Board of Water and Light',
    info:
      'Custom web and mobile application to view and report power outages for the City of Lansing, Michigan.',
    info2:
      'Built outage portal and mobile apps (Android and iOS) via Angular on the PhoneGap platform that utilized a REST API built on .NET with C#.',
    url: 'https://www.lbwl.com/outagecenter',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'routingbox.png',
    title: 'Routing Box',
    info:
      '(Project via EmergenceTek Group) Contract project, working as part of a team to convert a legacy desktop Windows app into an Angular web app.',
    info2:
      'Technologies include Angular 9, Typescript, RxJS, NgRx, and PrimeNG, integrated with a .NET Core backend REST API.',
    url: 'https://routingbox.com',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'joe@logicalmobility.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/LogicalMobility',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: 'https://codepen.io/LogicalMobility',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/logicalmobility/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/a1g0rithm',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
